import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class GuardService {

  constructor(
    private authenticationService: AuthenticationService,
    protected router: Router
  ) { }

  canActivate() {
    if (!this.authenticationService.authenticatedUser) {
        this.router.navigate(['/authentication/login']);
        return false;
    }
    return true;
  }
}
