import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';

//ACL
import { GuardService } from './@core/services/guard.service';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [GuardService],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      }, {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard-default/dashboard-default.module').then(m => m.DashboardDefaultModule)
      }, {
        path: 'basic',
        loadChildren: () => import('./pages/ui-elements/basic/basic.module').then(m => m.BasicModule)
      }, {
        path: 'notifications',
        loadChildren: () => import('./pages/ui-elements/advance/notifications/notifications.module').then(m => m.NotificationsModule)
      }, {
        path: 'bootstrap-table',
        loadChildren: () => import('./pages/ui-elements/tables/bootstrap-table/basic-bootstrap/basic-bootstrap.module').then(m => m.BasicBootstrapModule),
      }, {
        path: 'map',
        loadChildren: () => import('./pages/map/google-map/google-map.module').then(m => m.GoogleMapModule),
      },
      // , {
      //   path: 'user',
      //   loadChildren: () => import('./pages/user/profile/profile.module').then(m => m.ProfileModule)
      // }, 
      {
        path: 'simple-page',
        loadChildren: () => import('./pages/simple-page/simple-page.module').then(m => m.SimplePageModule)
      },
      {
        path: 'utilisateurs',
        loadChildren: () => import('./pages/utilisateur/utilisateur.module').then(m => m.UtilisateurModule)
      },
      {
        path: 'ventes',
        loadChildren: () => import('./pages/vente/vente.module').then(m => m.VenteModule)
      },
      {
        path: 'articles',
        loadChildren: () => import('./pages/article/article.module').then(m => m.ArticleModule)
      },
      {
        path: 'clients',
        loadChildren: () => import('./pages/client/client.module').then(m => m.ClientModule)
      },
      {
        path: 'fournisseurs',
        loadChildren: () => import('./pages/fournisseur/fournisseur.module').then(m => m.FournisseurModule)
      },
      {
        path: 'shops',
        loadChildren: () => import('./pages/magasin/magasin.module').then(m => m.MagasinModule)
      },
      {
        path: 'suppliers',
        loadChildren: () => import('./pages/supplier/supplier.module').then(m => m.SupplierModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule)
      },
      {
        path: 'stock',
        loadChildren: () => import('./pages/stock/stock.module').then(m => m.StockModule)
      },
      {
        path: 'sales',
        loadChildren: () => import('./pages/sale/sale.module').then(m => m.SaleModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'quotes',
        loadChildren: () => import('./pages/quote/quote.module').then(m => m.QuoteModule)
      },
      {
        path: 'thirds',
        loadChildren: () => import('./pages/third/third.module').then(m => m.ThirdModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
