import {Injectable} from '@angular/core';

import { AuthenticationService } from '../../@core/services/authentication.service';

import { ADMINITEMS } from './admin-items';
import { RESPSTOCKITEMS } from './resp-stock-items';
import { CHEFMAGASINITEMS } from './chef-magasin-items';
import { VENDEURITEMS } from './vendeur-items';
import {Role} from '../../@core/enum/role';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

@Injectable()
export class MenuItems {

  constructor(
    private auth: AuthenticationService
  ){

  }

  getAll(): Menu[] {
    if(Role.ADMIN === this.auth.currentRole){
      return ADMINITEMS;
    }
    
    if(Role.RESP_STOCK === this.auth.currentRole) return RESPSTOCKITEMS;

    if(Role.CHEF_MAGASIN === this.auth.currentRole) return CHEFMAGASINITEMS;

    if(Role.VENDEUR === this.auth.currentRole) return VENDEURITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
