export const RESPSTOCKITEMS = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Tableau de bord',
        type: 'link',
        icon: 'ti-dashboard'
      },
      // {
      //   state: 'ventes',
      //   short_label: 'V',
      //   name: 'Ventes',
      //   type: 'sub',
      //   icon: 'ti-shopping-cart',
      //   children: [
      //     {
      //       state: 'liste',
      //       name: 'Liste'
      //     }
      //   ]
      // },
      // {
      //   state: 'commandes',
      //   short_label: 'C',
      //   name: 'Commandes',
      //   type: 'sub',
      //   icon: 'ti-list',
      //   children: [
      //     {
      //       state: '',
      //       name: 'Liste'
      //     },
      //     {
      //       state: 'nouveau',
      //       name: 'Nouvelle'
      //     }
      //   ]
      // },
      {
        state: 'products',
        short_label: 'A',
        name: 'Produits',
        type: 'sub',
        icon: 'ti-clipboard',
        children: [
          {
            state: 'warehouses',
            name: 'Liste'
          },
          {
            state: 'new',
            name: 'Nouveau'
          },
          {
            state: 'shop',
            name: 'Produits par boutique'
          },
          {
            state: 'categories',
            name: 'Catégories'
          },
          {
            state: 'feature-types',
            name: 'Types de caractéristique'
          }
        ]
      },
      {
        state: 'stock',
        short_label: 'S',
        name: 'Stock',
        type: 'sub',
        icon: 'ti-archive',
        children: [
          {
            state: 'entries',
            name: 'Entrées'
          },
          {
            state: 'exits',
            name: 'Sorties'
          },
          {
            state: 'warehouses',
            name: 'Entrepôts'
          },
          {
            state: 'transfers',
            name: 'Transferts',
            type: 'sub',
            children: [
              {
                state: "list",
                name: 'Liste',

              },
              {
                state: 'new',
                name: 'Nouveau'
              }
            ]
          }
        ]
      },
      // {
      //   state: 'customers',
      //   short_label: 'C',
      //   name: 'Clients',
      //   type: 'link',
      //   icon: 'ti-wallet'
      // },
      {
        state: 'suppliers',
        short_label: 'D',
        name: 'Fournisseurs',
        type: 'link',
        icon: 'ti-truck'
      },
      {
        state: 'shops',
        short_label: 'M',
        name: 'Boutiques',
        type: 'link',
        icon: 'ti-home'
      }
    ],
  },
]