import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { AuthenticationService } from './@core/services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'JDS E.R.P';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    console.log(0);

    // On ramène à la page de connexion si l'utilsateur n'est pas authetifié
    if(!this.authenticationService.isAuthenticated()){
      this.router.navigate(['/authentication/login'])
    }


    this.router.events.subscribe((evt: any) => {

      if (!(evt instanceof NavigationEnd)) {
        if(!this.authenticationService.isAuthenticated() && evt.routerEvent.url !== '/authentication/login'){
          // this.router.navigate(['/authentication/login']);
          location.href = "/authentication/login"
        }
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
