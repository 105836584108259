import { Injectable, TemplateRef } from '@angular/core';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  
  toastOptions:ToastOptions = {
    title: "My title",
    msg: "The message",
    showClose: true,
    timeout: 5000,
    theme: 'bootstrap',
    onAdd: (toast:ToastData) => {
        // console.log('Toast ' + toast.id + ' has been added!');
    },
    onRemove: function(toast:ToastData) {
        //console.log('Toast ' + toast.id + ' has been removed!');
    }
  };

  constructor(
    private toastyService:ToastyService,
    private toastyConfig: ToastyConfig
  ) { 
    this.toastyConfig.theme = 'bootstrap';
  }

  success(message){
    this.toastOptions.title = "Succès";
    this.toastOptions.msg = message;
    this.toastyService.success(this.toastOptions);
  }

  info(message){
    this.toastOptions.title = "Information";
    this.toastOptions.msg = message;
    this.toastyService.info(this.toastOptions);
  }

  wait(message){
    this.toastOptions.title = "En attente";
    this.toastOptions.msg = message;
    this.toastyService.wait(this.toastOptions);
  }

  error(message){
    this.toastOptions.title = "Erreur";
    this.toastOptions.msg = message;
    this.toastyService.error(this.toastOptions);
  }

  warning(message){
    this.toastOptions.title = "Modification";
    this.toastOptions.msg = message;
    this.toastyService.warning(this.toastOptions);
  }
  
 
}
