export const VENDEURITEMS = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Tableau de bord',
        type: 'link',
        icon: 'ti-dashboard'
      },
      {
        state: 'sales',
        short_label: 'S',
        name: 'Ventes',
        type: 'sub',
        icon: 'ti-shopping-cart',
        children: [
          {
            state: 'shop-list',
            name: 'Liste'
          },
          {
            state: 'shop-new',
            name: 'Nouvelle'
          }
        ]
      },
      {
        state: 'quotes',
        short_label: 'S',
        name: 'Devis',
        type: 'sub',
        icon: 'ti-shopping-cart',
        children: [
          {
            state: 'list',
            name: 'Liste'
          },
          {
            state: 'new',
            name: 'Nouveau'
          }
        ]
      },
      // {
      //   state: 'commandes',
      //   short_label: 'C',
      //   name: 'Commandes',
      //   type: 'sub',
      //   icon: 'ti-list',
      //   children: [
      //     {
      //       state: '',
      //       name: 'Liste'
      //     },
      //     {
      //       state: 'nouveau',
      //       name: 'Nouvelle'
      //     }
      //   ]
      // },
      {
        state: 'products',
        short_label: 'A',
        name: 'Produits',
        type: 'sub',
        icon: 'ti-clipboard',
        children: [
          {
            state: 'product-shop',
            name: 'Liste'
          },
        ]
      },
      {
        state: 'thirds',
        short_label: 'C',
        name: 'Clients & Prospects',
        type: 'sub',
        icon: 'ti-wallet',
        children: [
          {
            state: 'customers',
            name: 'Clients',
          },
          {
            state: 'prospects',
            name: 'Prospects',
          },
          {
            state: 'categories',
            name: 'Catégories',
          },
        ]
      },
    ]
  },
]
